import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontFamily: 'MarkPro',
    colorPrimary: '#EA7555',
    colorPrimaryHover: '#FF8762',
    colorBgContainerDisabled: '#F5F5F5',
  },
  components: {
    Button: {
      fontWeight: 500,
      colorLink: '#EA7555',
      colorLinkHover: '#FF8762',
      defaultBorderColor: '#AEABA8',
      defaultColor: '#3E3A37',
      textTextColor: '#3E3A37',
    },
  },
};

export default theme;
